<template>
  <div>
    <div class="display-cover">
      <video style="width:85%; height: 100%;" autoplay playsinline></video>
      <canvas v-show="isCaptured" id="canvas" style="width:85%; height: 100%;"></canvas>
      <!-- <canvas class="d-none"></canvas> -->
      <!-- <img v-else src="@/assets/selfie.jpg" alt=""> -->

    <div class="controls">
      <a-button v-if="!openCamera" @click.prevent="toggleCamera" type="default" shape="round" :icon="isCaptured ? 'camera' : 'poweroff'">{{isCaptured ? 'Ambil Foto lagi' : 'Buka Webcam'}}</a-button>
      <a-button v-else @click.prevent="toggleCapture" type="default" shape="round" icon="camera">Tangkap Sekarang</a-button>
      <!-- <a-button type="primary" shape="circle" icon="upload" size="large" /> -->
    </div>
    </div>
    <hr>
    <a-form>
        <a-form-item
        label="Your Location"
      >
        <a-input :value="position" disabled />
      </a-form-item>
      </a-form>
  </div>
</template>

<script>
export default {
  props: ['visible', 'isClosed', 'position'],
  watch: {
    isClosed(newVal) {
      if (newVal) {
        const canvas = document.getElementById('canvas')
        const context = canvas.getContext('2d')
        context.clearRect(0, 0, canvas.width, canvas.height)
        const video = document.querySelector('video')
        const stream = video.srcObject
        this.openCamera = false
        video.pause()
        video.src = false
        if (stream) {
          const tracks = stream.getTracks()
          tracks[0].stop()
        }
      }
    },
  },
  data() {
    return {
      openCamera: false,
      isCaptured: false,
      inputPosition: '',
    }
  },
  methods: {
    async toggleCamera() {
      // this.photoData = null
      this.$emit('sendDataImgLoc', { image: null })
      this.isCaptured = false
      const canvas = document.getElementById('canvas')
      const context = canvas.getContext('2d')
      context.clearRect(0, 0, canvas.width, canvas.height)
      this.openCamera = !this.openCamera
      const constraints = {
        audio: false,
        video: true,
      }

      window.constraints = constraints

      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      const video = document.querySelector('video')
      // const videoTracks = stream.getVideoTracks()
      window.stream = stream // make variable available to browser console
      video.srcObject = stream
    },
    async toggleCapture() {
      this.isCaptured = true
      const video = document.querySelector('video')
      const canvas = document.getElementById('canvas')
      // const photo = document.getElementById('photo');
      const context = canvas.getContext('2d')
      const { width, height } = canvas
      canvas.width = width * 2
      canvas.height = height * 2
      context.drawImage(video, 0, 0, canvas.width, canvas.height)
      const data = canvas.toDataURL('image/png')
      context.clearRect(0, 0, canvas.width, canvas.height)
      canvas.width = width
      canvas.height = height
      context.drawImage(video, 0, 0, width, height)
      this.$emit('sendDataImgLoc', { image: data })
      this.openCamera = false
      // console.log(this.photoData)

      video.pause()
      video.src = false
      const stream = video.srcObject
      const tracks = stream.getTracks()
      tracks[0].stop()
      // const videoTracks = stream.getVideoTracks()
    },
    getCoordinates() {
      return new Promise(function(resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })
    },
  },
  async mounted() {
    // this.position = null
    // this.inputPosition = null
    // if (navigator.geolocation) {
    // const position =
    // await navigator.geolocation.getCurrentPosition(position => {
    //   this.$emit('sendDataImgLoc', { position })
    //   console.log(position)
    //   this.inputPosition = position.coords.latitude + ' ' + position.coords.longitude
    //   console.log(this.inputPosition)
    // }, null, { timeout: 10000 })
    // } else {
    //   console.log(navigator, 'oye')
    // }
  },
}
</script>

<style lang="scss">
.display-cover {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 300px;
  width: 100%;
  // margin: 5% auto;
  position: relative;

  video {
    //margin: 5% auto;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
  }
  img {
    //margin: 5% auto;
    width: 100%;
    height: 100%;
  }

  .controls {
    position: absolute;
    margin-bottom: 20px;
  }

  canvas {
    position: absolute
  }
}
</style>
