<template>
  <div>
    <a-modal
        :title="'Konfirmasi Absensi ' + (absentState.status === 'Masuk' ? 'Masuk' : 'Keluar')"
        :visible="visibleModalAttendance"
        @cancel="showModalAttendance"
        >
        <modal-Attendance :isClosed="isClosed" :position="this.dataImgLoc.position" @sendDataImgLoc="sendDataImgLoc"/>
        <template slot="footer">
            <a-button key="back" @click="showModalAttendance">
            Batalkan
            </a-button>
            <a-button key="submit" type="primary" :disabled="confirmLoadingModalAttendance" :loading="confirmLoadingModalAttendance" @click="handleOkModalAttendance">
            Absen Sekarang
            </a-button>
        </template>
    </a-modal>
    <div class="card assignmentsProgress">
        <div class="card-header card-header-flex">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <h2 class="font-size-18">Kehadiran Harian</h2>
          </div>
          <div class="mt-2">
            <a-icon @click="toDasbor" type="select" class="font-size-18" />
          </div>
            <!-- </div> -->
        </div>
        </div>
        <div class="card-body text-center px-4">
            <p class="text-muted mb-1">{{today}}</p>
            <h2>{{dateTime ? dateTime : '-'}}</h2>
            <table class="table table-bordered mb-0 mt-4">
            <tr>
                <td class="w-50 text-success">Jam Masuk</td>
                <td class="w-50 text-danger">Jam Keluar</td>
            </tr>
            <tr>
                <td class="text-success">{{absentToday.jam_masuk || '-'}}</td>
                <td class="text-danger">{{absentToday.jam_keluar || '-'}}</td>
            </tr>
            </table>
        </div>
        <div class="card-footer px-4">
          <!-- <a-tooltip :title="absentState.text" placement="topLeft" >
            <a-button :disabled="!absentState.status.masuk" class="w-100 mb-2" @click.prevent="showModalAttendance" :type="!absentState.status.masuk ? 'default' : 'primary'" size="large"><a-icon type="check-circle" /> Absen Masuk</a-button>
          </a-tooltip>
          <a-tooltip :title="absentState.text" placement="bottomLeft">
            <a-button :disabled="!absentState.status.keluar" class="w-100" @click.prevent="showModalAttendance" type="danger" size="large"><a-icon type="close-circle" /> Absen Keluar</a-button>
          </a-tooltip> -->
          <a-alert v-if="absentState.status === 'Selesai'" class="mb-4" :message="absentState.text" banner />
          <a-button :disabled="absentState.status === 'Selesai'" class="w-100 mb-2" @click.prevent="showModalAttendance" :type="absentState.status === 'Selesai' ? 'default' : absentState.status === 'Keluar' ? 'danger' : 'primary'" size="large">{{absentState.status === 'Selesai' ? 'Tidak Dapat Absen' : 'Absen ' + absentState.status}}</a-button>
        </div>
    </div>
  </div>
</template>

<script>
import ModalAttendance from './ModalAttendance'
import moment from 'moment'
import $ from 'jquery'
const today = moment().format('dddd, DD MMMM YYYY')
export default {
  name: 'TodayAttendance',
  components: {
    ModalAttendance,
  },
  data() {
    return {
      visibleModalAttendance: false,
      confirmLoadingModalAttendance: false,
      today,
      dateTime: null,
      absentToday: {},
      dataPayloadAbsent: {},
      isClosed: false,
      dataImgLoc: {
        image: null,
        position: null,
      },
      ipPublic: null,
      // absentState: {
      //   status: {
      //     masuk: false,
      //     keluar: false,
      //   },
      //   isLeave: false,
      //   text: '',
      // },
      absentState: {
        status: 'Selesai',
        hasShift: true,
        text: '',
      },
    }
  },
  watch: {
    isClosed(newVal) {
      if (newVal) {
        // console.log(newVal, 'masuk')
      }
    },
  },
  methods: {
    sendDataImgLoc(payload) {
      // console.log(payload)
      this.dataImgLoc = { ...this.dataImgLoc, ...payload }
    },
    async showModalAttendance() {
      if (navigator.geolocation) {
        const position = await this.getCoordinates()
        console.log(position, 'oyeee')
        this.dataImgLoc.position = position.coords.latitude + ', ' + position.coords.longitude
      } else {
        console.log(navigator, 'oye')
      }
      this.visibleModalAttendance = !this.visibleModalAttendance
      this.isClosed = !this.visibleModalAttendance
    },
    getOS() {
      const userAgent = window.navigator.userAgent
      const platform = window.navigator.platform
      const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
      const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
      const iosPlatforms = ['iPhone', 'iPad', 'iPod']
      let os = null

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS'
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows'
      } else if (/Android/.test(userAgent)) {
        os = 'Android'
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux'
      } else {
        os = 'Unknown'
      }

      return os
    },
    getBrowser() {
      let browser = null
      const userAgentString = navigator.userAgent
      if (userAgentString.indexOf('OP') > -1 && userAgentString.indexOf('Chrome') === -1) browser = 'Safari'
      else if (userAgentString.indexOf('Safari') > -1 && userAgentString.indexOf('Chrome') === -1) browser = 'Safari'
      else if (userAgentString.indexOf('Chrome') > -1) browser = 'Chrome'
      else if (userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1) browser = 'Internet Explorer'
      else if (userAgentString.indexOf('Firefox') > -1) browser = 'Firefox'
      else browser = 'Unknown'

      return browser
    },
    async handleOkModalAttendance() {
      this.confirmLoadingModalAttendance = true
      if (!this.dataImgLoc.image) {
        this.confirmLoadingModalAttendance = false
        return this.$notification.error({
          message: 'Maaf',
          description: 'Anda belum menangkap foto diri. Harap gunakan webcam untuk menangkap foto diri anda.',
        })
      }

      // const image = await getImage(this.dataImgLoc.image)
      // console.log(image)
      const { ip } = await $.getJSON('https://api.ipify.org?format=json')
      const os = this.getOS()
      const browser = this.getBrowser()
      const statusAbsensi = this.absentState.status.toLowerCase()
      // console.log(ip)
      this.$store.dispatch('absensiKaryawan/UPDATE_ABSENT_EMPLOYEE', {
        statusAbsensi,
        // position: this.dataImgLoc.position,
        // image,
        ...this.dataImgLoc,
        ip,
        os,
        browser,
        device: os === 'Android' || os === 'iOS' ? 'Mobile' : 'Desktop',
      })
        .then(data => {
          if (data) {
            this.isClosed = true
            this.confirmLoadingModalAttendance = false
            this.$notification.success({
              message: 'Sukses.',
              description: 'Sukses absensi ' + statusAbsensi + ' untuk hari ini.',
            })
            this.fetchAbsenToday()
          }
        })
        .catch(err => {
          const text = err.response.data.data
          this.isClosed = true
          this.confirmLoadingModalAttendance = false
          this.$notification.error({
            message: 'Gagal.',
            description: 'Gagal absensi ' + statusAbsensi + ' untuk hari ini. ' + text,
          })
          this.fetchAbsenToday()
        })
      this.visibleModalAttendance = !this.visibleModalAttendance

      // function getImage(image) {
      //   return new Promise((resolve, reject) => {
      //     const fReader = new FileReader()

      //     fReader.onload = () => {
      //       resolve(image)
      //     }

      //     fReader.readAsDataURL(image)
      //   })
      // }
    },
    toDasbor() {
      this.$router.push({ name: 'Dasbor Pegawai' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: [''],
      })
    },
    realtimeClock() {
      setInterval(() => {
        this.dateTime = moment().format('h:mm:ss A')
        // console.log(this.dateTime)
      }, 1000)
    },
    async fetchAbsenToday() {
      // this.$store.dispatch('absensiKaryawan/FETCH_TODAY_ABSENT')
      //   .then(data => {
      //     if (data) {
      //       const shift = data.shift
      //       if ('jam_masuk' in shift) {
      //         // if (shift.jam_masuk)
      //         this.absentToday = data
      //       } else {

      //       }
      //     }
      //   })
      const data = await this.$store.dispatch('absensiKaryawan/FETCH_TODAY_ABSENT')
      // console.log(data)
      const shift = data.shift
      if (shift) {
        console.log(shift)
        const jamMasukShift = moment(shift.jadwal_shifts[0].jam_masuk, 'HH:mm')
        const absensiDibuka = jamMasukShift.add(Number(shift.absensi_dibuka) * -1, 'minutes')
        const jamKeluarShift = moment(shift.jadwal_shifts[0].jam_keluar, 'HH:mm')
        const absensiDitutup = jamKeluarShift.add(Number(shift.absensi_ditutup), 'minutes')

        this.absentToday = data.absensi || {}
        if (data.absensi) {
          // console.log('masukkkk')
          if (data.kegiatan || data.cuti) {
          // this.absentState.isLeave = true
            this.absentState.text = 'Ada jadwal libur/cuti di hari ini.'
          } else if (data.izin) {
            // this.absentState.isLeave = true
            this.absentState.text = 'Anda sedang izin hari ini.'
          } else {
            // console.log(absensiDibuka.format('HH:mm'), absensiDitutup.format('HH:mm'), shift)
            this.absentState.status = 'Selesai'
            if (moment().isBetween(absensiDibuka, absensiDitutup)) {
              // console.log('masuk')
              if (!this.absentToday.jam_masuk) this.absentState.status = 'Masuk'
              else if (!this.absentToday.jam_keluar) this.absentState.status = 'Keluar'
              else {
                this.absentState.status = 'Selesai'
                this.absentState.text = 'Anda sudah selesai absen untuk hari ini.'
              }
            } else {
              this.absentState.status = 'Selesai'
              this.absentState.text = 'Tidak berada pada waktu absensi.'
            }
          }
        } else {
          if (moment().isBetween(absensiDibuka, absensiDitutup)) this.absentState.status = 'Masuk'
          else {
            this.absentState.text = 'Tidak berada pada waktu absensi.'
            this.absentState.status = 'Selesai'
          }
        }
      } else {
        this.absentState.status = 'Selesai'
        this.absentState.hasShift = false
        this.absentState.text = 'Anda belum memiliki waktu kerja. Hubungi bagian SDM/HR untuk berkoordinasi.'
      }
    },
    getCoordinates() {
      return new Promise(function(resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })
    },
  },
  computed: {
    // statusTextAbsent() {
    //   if (!this.absentToday.jam_masuk) return { status: 'masuk', text: 'Mulai Bekerja Hari Ini' }
    //   if (!this.absentToday.jam_keluar) return { status: 'keluar', text: 'Selesai Bekerja Hari Ini' }
    //   if (this.absentToday.jam_keluar && this.absentToday.jam_keluar) return { status: 'selesai', text: 'Anda Sudah Selesai Absen Hari Ini' }
    //   return { status: 'masuk', text: 'Mulai Bekerja Hari Ini' }
    // },
  },
  async mounted() {
    this.isClosed = false
    this.realtimeClock()
    await this.fetchAbsenToday()
    const { ip } = await $.getJSON('https://api.ipify.org?format=json')
    this.ipPublic = ip
  },
}
</script>

<style>

</style>
