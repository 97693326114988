<template>
  <div>
      <h2>My Attendance</h2>
      <a-row :gutter="32" class="mt-5">
        <a-col :xs="24" :lg="8">
          <today-attendance />
          <div class="card">
            <div class="card-header card-header-flex">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div>
                    <h2 class="font-size-18">Mengajukan Cuti</h2>
                  </div>
                </div>
            </div>
            <div class="card-body">
              <a-form>
                <a-form-item label="Tipe Cuti">
                  <a-select
                      size="large"
                      style="width: 100%; height: 40px"
                    >
                      <a-select-option key="medical">Medical</a-select-option
                      >
                    </a-select>
                </a-form-item>
                <a-form-item label="Start at">
                    <a-date-picker
                      v-model="startAt"
                      :disabled-date="disabledStartDate"
                      show-time
                      format="YYYY-MM-DD"
                      @openChange="handleStartOpenChange"
                      style="width: 100%"
                      size="large"
                    />
                  </a-form-item>
                  <a-form-item label="End at">
                    <a-date-picker
                      v-model="endAt"
                      :disabled-date="disabledEndDate"
                      show-time
                      format="YYYY-MM-DD"
                      :open="endOpen"
                      @openChange="handleEndOpenChange"
                      style="width: 100%"
                      size="large"
                    />
                  </a-form-item>
                  <a-form-item label="Description">
                    <a-textarea />
                  </a-form-item>
                  <a-button type="primary" size="large" block>Apply Now</a-button>
              </a-form>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :lg="16">
          <div class="card">
            <div class="card-header card-header-flex">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div>
                    <h2 class="font-size-18">Ringkasan Kehadiran</h2>
                  </div>
                </div>
            </div>
            <div class="card-body">
              <div class="row mt-3 mb-4">
                <div class="col-lg-4">
                  <div class="item mb-xl-0 mb-3">
                    <!-- <span :class="$style.icon">
                      <i class="fas fa-percentage" />
                    </span> -->
                    <div class="desc">
                      <span class="title">Persentase Kehadiran</span>
                      <p>90%</p>
                    </div>
                    <div class="line bg-success" />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="item mb-xl-0 mb-3" >
                    <span class="icon">
                      <i class="fe fe-command" />
                    </span>
                    <div class="desc">
                      <span class="title">Total Jam Bekerja</span>
                      <p>52h 21m</p>
                    </div>
                    <div class="line bg-info" />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="item mb-xl-0 mb-3">
                    <span class="icon">
                      <i class="fe fe-star" />
                    </span>
                    <div class="desc">
                      <span class="title">Sisa Tunjangan Cuti</span>
                      <p>5</p>
                    </div>
                    <div class="line bg-warning" />
                  </div>
                </div>
              </div>
              <div class="calendar-container">
                <a-calendar>
                  <ul class="mb-4" slot="dateCellRender" slot-scope="value">
                    <li v-for="item in getListData(value)" :key="item.content">
                      <a-badge :status="item.type" :text="item.content" />
                    </li>
                  </ul>
                </a-calendar>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
  </div>
</template>

<script>
import TodayAttendance from '@/components/app/Dashboard/TodayAttendance'
export default {
  components: {
    TodayAttendance,
  },
  data() {
    return {
      startAt: '',
      endAt: '',
      endOpen: false,
    }
  },
  methods: {
    disabledStartDate(startValue) {
      const endValue = this.endAt
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.startAt
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    getListData(value) {
      let listData
      if (value.month() === 0) {
        switch (value.date()) {
          case 4:
            listData = [
              { type: 'warning', content: 'Cuti' },
            ]; break
          case 5:
            listData = [
              { type: 'warning', content: 'Cuti' },
            ]; break
          case 6:
            listData = [
              { type: 'warning', content: 'Cuti' },
            ]; break
          case 7:
            listData = [
              { type: 'warning', content: 'Cuti' },
            ]; break
          case 8:
            listData = [
              { type: 'warning', content: 'Cuti' },
            ]; break
          case 11:
            listData = [
              { type: 'error', content: 'Absen' },
            ]; break
          case 12:
            listData = [
              { type: 'success', content: 'Hadir' },
            ]; break
          case 13:
            listData = [
              { type: 'success', content: 'Hadir' },
            ]; break
          case 14:
            listData = [
              { type: 'success', content: 'Hadir' },
            ]; break
          case 15:
            listData = [
              { type: 'success', content: 'Hadir' },
            ]; break
          case 18:
            listData = [
              { type: 'success', content: 'Hadir' },
            ]; break
          case 19:
            listData = [
              { type: 'success', content: 'Hadir' },
            ]; break
          default:
        }
      }
      return listData || []
    },
    getMonthData(value) {
      console.log(value)
      if (value.month() === 8) {
        return 1394
      }
    },
  },
}
</script>

<style lang="css">
.item {
  position: relative;
  padding-bottom: 1rem;
}

.title {
  font-weight: 600;
}

.line {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 100%;
  border-radius: 2px;
}
.calendar-container .ant-select.ant-select-enabled.ant-fullcalendar-year-select .ant-select-selection.ant-select-selection--single {
  height: 40px;
}

.calendar-container .ant-select.ant-select-enabled.ant-fullcalendar-month-select .ant-select-selection.ant-select-selection--single {
  height: 40px;
}

.calendar-container .ant-select.ant-select-enabled.ant-fullcalendar-year-select .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered {
  line-height: 38px;
}

.calendar-container .ant-select.ant-select-enabled.ant-fullcalendar-month-select .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered {
  line-height: 38px;
}

.calendar-container .ant-radio-group.ant-radio-group-outline.ant-radio-group-default .ant-radio-button-wrapper.ant-radio-button-wrapper {
  height: 40px;
  /* display: none !important; */
}

.calendar-container .ant-radio-group.ant-radio-group-outline.ant-radio-group-default .ant-radio-button-wrapper.ant-radio-button-wrapper span{
  line-height: 36px;
}

.calendar-container .ant-fullcalendar-fullscreen .ant-fullcalendar-header {
  text-align: left !important;
}

.calendar-container .ant-fullcalendar.ant-fullcalendar-full.ant-fullcalendar-fullscreen {
  margin-top: 20px;
}

.calendar-container .ant-fullcalendar.ant-fullcalendar-full.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body {
  padding: 0;
}
</style>
